import React from 'react'
import Item from './Item'

export default function ItemsPedido({ pedido }) {
    return (
        <div className='flex flex-col bg-white border border-gray-100 rounded-md h-full p-2 overflow-y-auto'>
            <div className='flex flex-1 flex-col justify-between'>
                <div className='p-2'>
                    {pedido.itensPedido.map((item, index) => (
                        <Item key={index} item={item} />
                    ))}
                </div>
            </div>

        </div>
    )
}
