
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import Global from "../Global";

const firebaseConfig = {
  apiKey: Global.firebaseApiKey,
  authDomain: Global.firebaseAuthDomain,
  projectId: Global.firebaseProjectId,
  storageBucket: Global.firebaseStorageBucket,
  messagingSenderId: Global.firebaseMessagingSenderId,
  appId: Global.firebaseAppId,
  measurementId: Global.firebaseMeasurementId
};

const app = initializeApp(firebaseConfig);
export const imageDb = getStorage(app)