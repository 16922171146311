import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const success = await login(username, password);
        if (success) {
            navigate('/');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <form
                className="bg-white p-8 rounded-lg shadow-md w-80"
                onSubmit={handleSubmit}
            >
                <div className='flex w-full items-center justify-center p-4 mb-3'>
                    <img src={'logopng.png'} alt='Descrição da imagem' className=' w-full md:w-auto rounded-md' />
                </div>
                

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Email"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-6">
                    <input
                        type="password"
                        placeholder="Senha"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition duration-300 mb-4"
                >
                    Login
                </button>
                <button
                    type="button"
                    className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    onClick={() => navigate('/register')}
                >
                    Cadastre-se
                </button>
            </form>
        </div>
    );
};

export default LoginPage;