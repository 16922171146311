import React, { useEffect, useState } from 'react'
import { FaPlus } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import Categoria from './Categoria/Categoria';
import BackendService from '../integrations/BackendService';
import {
  CurrencyInput,
  Currencies,
  Locales
} from 'input-currency-react';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';


export default function Cardapio() {
  const backendService = new BackendService();

  const [open, setOpen] = useState(false);
  const [listaCategoria, setListaCategoria] = useState([]);
  const [listaCategoriaOrdenar, setListaCategoriaOrdenar] = useState([]);
  const [listaAdicionais, setListaAdicionais] = useState([]);
  const [modalAdicionarAdicional, setModalAdicionarAdicional] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [novoAdicional, setNovoAdicional] = useState({ nome: '', preco: 0.0 });
  const [itemAtual, setItemAtual] = useState({ nome: '', preco: 0.0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await backendService.buscarCardapioParaRestaurante();
        const responseadicionais = await backendService.buscarAdicionaisRestaurante();
        setListaAdicionais(responseadicionais)
        setListaCategoria(response)
        setListaCategoriaOrdenar(response)
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  // Estado para armazenar o termo de busca
  const [termoBusca, setTermoBusca] = useState('');
  // Função para atualizar o estado do termo de busca
  const handleBusca = (event) => {
    setTermoBusca(event.target.value);
  };
  // Filtra os dados com base no termo de busca
  const dadosFiltrados = listaAdicionais.filter(item => {
    const termoBuscaMinusculo = termoBusca.toLowerCase();
    return item.nome.toLowerCase().startsWith(termoBuscaMinusculo);
  });
  const [activeTab, setActiveTab] = useState('tela1');

  const [modalAberto, setModalAberto] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const abrirModalAdicionarCategoria = () => {
    setModalAberto(true);
    setMensagemErro('');
    // Define o estado do modal como aberto ao clicar no botão
  };
  const fecharModalAdicionarCategoria = () => {
    setModalAberto(false); // Define o estado do modal como fechado
    setNomeCategoria("")
  };
  const handleChangeNomeCategoria = (event) => {
    setNomeCategoria(event.target.value);
  };

  const [nomeCategoria, setNomeCategoria] = useState("");
  const adicionarCategoria = async () => {
    if (nomeCategoria.trim() !== '') {
      try {
        await backendService.criarCategoria(nomeCategoria);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } catch (error) {
        console.error(error.message);
      }
      setNomeCategoria("")
      fecharModalAdicionarCategoria();
    } else {
      setMensagemErro('Um nome para sua nova categoria é obrigatório.');
    }
  };

  const handleExcluirCategoria = (categoriaId) => {
    const novasCategorias = listaCategoria.filter(categoria => categoria.id !== categoriaId);
    setListaCategoria(novasCategorias);
  };

  const handleAdicionarAdicional = async () => {
    // Adicione a lógica para adicionar o novo item à lista
    try {
      const response = await backendService.criarAdicional(novoAdicional);
      setListaAdicionais([...listaAdicionais, response])
    } catch (error) {
      console.error(error.message);
    }
    setModalAdicionarAdicional(false);
    setNovoAdicional({ nome: '', preco: '' });
  };

  const handleChangePreco = (campo, valor) => {
    const numericValue = parseFloat(valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.'));
    setNovoAdicional({ ...novoAdicional, preco: isNaN(numericValue) ? 0 : numericValue })
  };
  const handleChangePrecoEditar = (campo, valor) => {
    if (valor) {
      // Remove espaços, símbolo de moeda e formatação para converter para número
      const numericValue = parseFloat(valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.'));
      setItemAtual({ ...itemAtual, preco: isNaN(numericValue) ? 0 : numericValue });
    } else {
      setItemAtual({ ...itemAtual, preco: 0 });
    }
  };

  const openEditModal = (item) => {
    setItemAtual(item);
    setEditModalVisible(true);
  };

  const handleEditAdicional = async (item) => {
    try {
      await backendService.editarAdicional(item);
      setListaAdicionais(prevLista => {
        // Substitua o item correspondente
        return prevLista.map(adicional =>
          adicional.id === item.id ? item : adicional
        );
      });
      Swal.fire(
        'Adicional alterado!',
        'O item foi editado.',
        'success'
      );
    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi possível alterar o adicional.',
        'error'
      );
    }
    setEditModalVisible(false)
  };

  const confirmDelete = (item) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Você não poderá reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, deletar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAdicional(item);

      }
    });
  };

  const handleDeleteAdicional = async (item) => {
    try {
      await backendService.deletarAdicional(item.id);
      setListaAdicionais(prevLista => prevLista.filter(adicional => adicional.id !== item.id));
      Swal.fire(
        'Deletado!',
        'O item foi deletado.',
        'success'
      );
      setEditModalVisible(false)
    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi possível deletar o adicional.',
        'error'
      );
    }
  };

  const handleCloseOrdenar = () => {
    setOpen(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Atualize a ordem dos itens
    return result.map((item, index) => ({
      ...item,
      ordem: index // Atualiza o campo ordem para refletir a nova posição
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = reorder(listaCategoriaOrdenar, result.source.index, result.destination.index);
    setListaCategoriaOrdenar(updatedItems);
  };

  const handleSalvar = async () => {
    // Cria uma nova lista contendo apenas os campos id e ordem
    const categoriaOrdenada = listaCategoriaOrdenar.map(({ id, ordem }) => ({
      id,
      ordem
    }));
    try {
      Swal.fire({
        title: 'Ordenando...',
        text: 'Por favor, aguarde enquanto ordenamos seu cardápio.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      await backendService.ordenarCategoria(categoriaOrdenada);
      Swal.fire({
        title: "Ordenado!",
        text: "Categoria ordenada com sucesso.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          window.location.reload();
        }
      });

    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi ordenar sua lista de categoria.',
        'error'
      );
    }
  };

  return (
    <div className='flex flex-col py-4'>
      <div className="flex space-x-2 border-b-2 border-gray-300 mb-4">
        <button
          onClick={() => {
            setActiveTab('tela1');
            window.location.reload();
          }}
          className={`py-2 px-4 rounded-t-lg transition-colors duration-300 ${activeTab === 'tela1'
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
        >
          Cardápio
        </button>
        <button
          onClick={() => setActiveTab('tela2')}
          className={`py-2 px-4 rounded-t-lg transition-colors duration-300 ${activeTab === 'tela2'
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
        >
          Adicionais
        </button>
      </div>
      {activeTab === 'tela1' && (
        <div className='flex flex-col p-4 max-h-[90vh] overflow-y-auto'>
          <div className='flex'>
            <button className="flex items-center bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2" onClick={abrirModalAdicionarCategoria}>
              <FaPlus /> <span className='ml-2'>Adicionar Categoria</span>
            </button>
            <button className="flex items-center bg-blue-500 text-white font-bold py-2 px-4 rounded mr-2" onClick={() => setOpen(true)}>
              <BsList size={20} /> <span className='ml-2'>Ordenar categoria</span>
            </button>
          </div>

          <div>
            {listaCategoria.map((categoria, index) => (
              <Categoria key={index} categoria={categoria} onExcluir={handleExcluirCategoria} listaAdicionais={listaAdicionais} />
            ))}
          </div>

          {modalAberto && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="flex flex-col items-center justify-center bg-white p-4 rounded-md">
                <h2 className="text-lg font-semibold mb-4">Adicionar Categoria</h2>
                {/* Conteúdo do formulário para adicionar categoria */}
                <input
                  type="text"
                  value={nomeCategoria}
                  onChange={handleChangeNomeCategoria}
                  className="border border-gray-300 rounded-md p-2 mb-4 w-full"
                  placeholder="Nome da categoria"
                />
                {mensagemErro && <p className="text-red-600 mb-2">{mensagemErro}</p>}
                <div className='flex w-full justify-between'>
                  <button className="flex-1 mr-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={fecharModalAdicionarCategoria}>
                    Fechar
                  </button>
                  <button className="flex-1 ml-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={adicionarCategoria}>
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      )}
      {activeTab === 'tela2' && (
        <div className="max-w-lg mx-auto ">
          <input
            type="text"
            placeholder="Buscar por nome"
            value={termoBusca}
            onChange={handleBusca}
            className="w-full px-4 py-2 border rounded-md mb-4"
          />
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setModalAdicionarAdicional(true)}
          >
            Criar adicional
          </button>
          <div className="max-h-[65vh] overflow-y-scroll mt-4">
            <table className="w-full border-collapse border border-gray-300 shadow-lg rounded-lg overflow-hidden">
              <thead className="bg-gradient-to-r bg-blue-300 ">
                <tr>
                  <th className="py-3 px-6 border-b border-gray-200">Nome</th>
                  <th className="py-3 px-6 border-b border-gray-200">Preço</th>
                  <th className="py-3 px-6 border-b border-gray-200">Ações</th>
                </tr>
              </thead>
              <tbody>
                {dadosFiltrados.map((item, index) => (
                  <tr key={index} className="bg-white odd:bg-gray-100 hover:bg-blue-50">
                    <td className="py-3 px-6 border-b border-gray-200 text-center">{item.nome}</td>
                    <td className="py-3 px-6 border-b border-gray-200 text-center">R$ {item.preco.toFixed(2)}</td>
                    <td className="py-3 px-6 border-b border-gray-200 text-center">
                      <button
                        onClick={() => openEditModal(item)}
                        className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 mr-2"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => confirmDelete(item)}
                        className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Deletar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Dialog open={open} onClose={handleCloseOrdenar} maxWidth="xs" fullWidth>
        <DialogTitle>Ordenar Categoria</DialogTitle>
        <DialogContent>
          <div className='flex flex-col'>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable' direction='vertical'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='flex flex-col gap-2'
                  >
                    {listaCategoriaOrdenar.map((item, index) => (
                      <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='flex rounded-md bg-slate-100 border p-2 items-center'
                          >
                            <BsList size={20} /><span className='font-bold ml-2'>{item.nome}</span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrdenar} >
            Fechar
          </Button>
          <Button onClick={handleSalvar} variant="contained" >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {modalAdicionarAdicional && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Adicionar Novo Item</h2>
            <input
              type="text"
              placeholder="Nome"
              value={novoAdicional.nome}
              onChange={(e) => {
                const { value } = e.target;
                // Verificar se o valor contém apenas letras (sem números)
                setNovoAdicional({ ...novoAdicional, nome: value });
              }}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <CurrencyInput options={{
              precision: 2,
              style: "currency",
              locale: Locales["Portuguese (Brazil)"], // Format Type
              i18nCurrency: Currencies["Brazilian Real"] // Symbol
            }}
              type="number"
              placeholder="Preço"
              value={novoAdicional.preco.toFixed(2)}
              onChangeEvent={handleChangePreco}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
                onClick={() => setModalAdicionarAdicional(false)}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleAdicionarAdicional}
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      )}

      {editModalVisible && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Editar Item</h2>
            <input
              type="text"
              placeholder="Nome"
              value={itemAtual.nome}
              onChange={(e) => setItemAtual({ ...itemAtual, nome: e.target.value })}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <CurrencyInput options={{
              precision: 2,
              style: "currency",
              locale: Locales["Portuguese (Brazil)"], // Format Type
              i18nCurrency: Currencies["Brazilian Real"] // Symbol
            }}
              type="number"
              placeholder="Preço"
              value={itemAtual.preco.toFixed(2)}
              onChangeEvent={handleChangePrecoEditar}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
                onClick={() => setEditModalVisible(false)}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => handleEditAdicional(itemAtual)}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}

    </div>

  )
}
