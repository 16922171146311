import React from 'react';

const Suporte = () => {
  const handleOpenWhatsApp = () => {
    const phoneNumber = '55022999687288'; // Número de telefone para o WhatsApp
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="text-center">
        <p className="text-lg mb-4">Entre em contato conosco via WhatsApp:</p>
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleOpenWhatsApp}
        >
          Abrir WhatsApp
        </button>
      </div>
    </div>
  );
};

export default Suporte;
