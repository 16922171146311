import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const RegisterPage = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('Casimiro de Abreu');
    const [estado, setEstado] = useState('RJ');
    const [cep, setCep] = useState('28860000');
    const [nomeEstabelecimento, setNomeEstabelecimento] = useState('');
    const [categoria, setCategoria] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmSenha, setConfirmSenha] = useState('');
    const [cpf, setCpf] = useState('');

    const { register } = useAuth();
    const navigate = useNavigate();

    function validarSenha() {
        // Pelo menos uma letra maiúscula e um caractere especial
        var regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
    
        
        return !regex.test(senha);
    }

    const formatCEP = (text) => {
        let formattedText = text.replace(/\D/g, '');

            formattedText = formattedText.replace(/^(\d{5})(\d)/, '$1-$2');

        return formattedText;
    };

    const formatarTelefone = (text) => {
        // Remove caracteres não numéricos
        let formattedText = text.replace(/[^\d]/g, '');

        if (formattedText.length === 0) {
            return '';
        }

        let formattedPhoneNumber = '(';
        if (formattedText.length > 2) {
            formattedPhoneNumber += `${formattedText.substring(0, 2)}) `;
        } else {
            formattedPhoneNumber += `${formattedText}`;
            return formattedPhoneNumber; // Retorna somente o DDD
        }

        if (formattedText.length > 3) {
            formattedPhoneNumber += `${formattedText.substring(2, 3)} `;
        } else {
            formattedPhoneNumber += `${formattedText.substring(2)}`;
            return formattedPhoneNumber; // Retorna DDD + primeiro número
        }

        if (formattedText.length > 7) {
            formattedPhoneNumber += `${formattedText.substring(3, 7)}-${formattedText.substring(7, 11)}`;
        } else {
            formattedPhoneNumber += `${formattedText.substring(3)}`;
        }

        return formattedPhoneNumber;
    };
    const onChangeTelefone = (event) => {
        // Remover caracteres não numéricos
        const text = event.target.value;
        const numericText = text.replace(/[^\d]/g, '');
        // Atualizar o estado com apenas os números
        if (numericText.length <= 11) {
            setTelefone(numericText);
        }
    };

    const formatCPF = (text) => {
        // Remove caracteres não numéricos
        let formattedText = text.replace(/\D/g, '');

        // Aplica a máscara: XXX.XXX.XXX-XX
        formattedText = formattedText.replace(/^(\d{3})(\d)/, '$1.$2');
        formattedText = formattedText.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
        formattedText = formattedText.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');

        return formattedText;
    };
    const handleChangeCpf = (event) => {
        const text = event.target.value;
        const rawCPF = text.replace(/\D/g, '');
        if (rawCPF.length <= 11) {
            setCpf(rawCPF);
        }
    };

    const formatCNPJ = (text) => {
        // Remove caracteres não numéricos
        let formattedText = text.replace(/\D/g, '');

        // Aplica a máscara: XX.XXX.XXX/0001-XX
        formattedText = formattedText.replace(/^(\d{2})(\d)/, '$1.$2');
        formattedText = formattedText.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        formattedText = formattedText.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
        formattedText = formattedText.replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');

        return formattedText;
    };
    const handleChangeCnpj = (event) => {
        const text = event.target.value;
        const rawCNPJ = text.replace(/\D/g, '');
        if (rawCNPJ.length <= 14) {
            setCnpj(rawCNPJ);
        }
    };

    function validarEmail() {
        // Expressão regular para verificar o formato do email
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!regex.test(email)) {
            Swal.fire(
                'Opss!',
                'O email informado não é válido.',
                'error'
              );
            return false;
        }
    
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (senha !== confirmSenha) {
            Swal.fire(
                'Opss!',
                'As senhas não coincidem',
                'error'
              );
            return;
        }

        if (!nome || !cpf || !email || !telefone || !rua || !numero || !bairro || !nomeEstabelecimento || !categoria || !cnpj || !senha || !confirmSenha) {
            Swal.fire(
                'Opss!',
                'Por favor, preencha todos os campos obrigatórios.',
                'error'
              );
            return;
        }

        validarEmail()
        
        if(validarSenha()){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'A senha deve conter pelo menos uma letra maiúscula e um caractere especial.'
            });
            return;
        }

        if (telefone.length !== 11) {
            Swal.fire(
                'Opss!',
                'O telefone deve ter 11 caracteres.',
                'error'
              );
            return;
        }
        
        // Verifica se o CPF tem exatamente 11 dígitos (considerando apenas os dígitos numéricos)
        if (cpf.replace(/[^\d]/g, '').length !== 11) {
            Swal.fire(
                'Opss!',
                'O CPF deve ter 11 caracteres.',
                'error'
              );
            return;
        }

        const userDetails = {
            nome: nome,
            cpf,
            email,
            telefone: telefone,
            endereco: {
                logradouro: rua,
                numero: numero,
                complemento: complemento,
                cidade: cidade,
                estado: estado,
                cep,
                bairro
            },
            restaurante: {
                nome: nomeEstabelecimento,
                categoria: categoria,
                cnpj
            },
            senha: senha,
        };

        try {
            const response = await register(userDetails);
            if(response){
                navigate("/login")    
            }            
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <form
                className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl"
                onSubmit={handleSubmit}
            >
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">Cadastro</h2>

                <h3 className="text-xl font-semibold mb-4 text-gray-600">Dados Pessoais</h3>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Nome"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="text"
                            placeholder="CPF"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={formatCPF(cpf)}
                            onChange={handleChangeCpf}
                        />
                    </div>
                </div>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="email"
                            placeholder="E-mail"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="text"
                            placeholder="Telefone"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={formatarTelefone(telefone)}
                            onChange={onChangeTelefone}
                        />
                    </div>
                </div>

                <h3 className="text-xl font-semibold mb-4 text-gray-600">Dados do Estabelecimento</h3>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Nome do Estabelecimento"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={nomeEstabelecimento}
                            onChange={(e) => setNomeEstabelecimento(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="text"
                            placeholder="CNPJ"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={formatCNPJ(cnpj)}
                            onChange={handleChangeCnpj}
                        />
                    </div>
                </div>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Tipo de Cozinha"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                        />
                    </div>

                </div>

                <h3 className="text-xl font-semibold mb-4 text-gray-600">Endereço do Estabelecimento</h3>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="CEP"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={formatCEP(cep)}
                            readOnly
                        />
                    </div>
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Cidade"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={cidade}
                            readOnly
                            onChange={(e) => setCidade(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2">
                        <input
                            type="text"
                            placeholder="Estado"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={estado}
                            readOnly
                            onChange={(e) => setEstado(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Rua"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={rua}
                            onChange={(e) => setRua(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="text"
                            placeholder="Bairro"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="text"
                            placeholder="Número"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="text"
                            placeholder="Complemento"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                        />
                    </div>
                </div>



                <h3 className="text-xl font-semibold mb-4 text-gray-600">Segurança</h3>

                <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                        <input
                            type="password"
                            placeholder="Senha"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <input
                            type="password"
                            placeholder="Confirme a Senha"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={confirmSenha}
                            onChange={(e) => setConfirmSenha(e.target.value)}
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                    Registrar
                </button>
                <Link to="/login" className="block text-center mt-4 text-blue-500 hover:text-blue-600">
                    Já tem uma conta? Faça login aqui.
                </Link>
            </form>
        </div>
    );


}

export default RegisterPage;