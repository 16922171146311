import {
	HiOutlineViewGrid,
	HiOutlineShoppingCart,
	HiOutlineUsers,
	HiOutlineQuestionMarkCircle,
	HiOutlineDocumentText 
} from 'react-icons/hi'
import { AiOutlineSetting } from "react-icons/ai";

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'painel',
		label: 'Painel',
		path: '/',
		icon: <HiOutlineViewGrid />
	},
	{
		key: 'cardapio',
		label: 'Cardápio',
		path: '/cardapio',
		icon: <HiOutlineShoppingCart />
	},
	{
		key: 'historico pedidos',
		label: 'Histórico Pedidos',
		path: '/historico-pedidos',
		icon: <HiOutlineUsers />
	},
	{
		key: 'configuração restaurante',
		label: 'Configurações Restaurante',
		path: '/configuracao',
		icon: <AiOutlineSetting />
	},
	{
		key: 'configuração restaurante',
		label: 'Cupom',
		path: '/cupom',
		icon: <HiOutlineDocumentText  />
	}
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	
	{
		key: 'suporte',
		label: 'Ajuda',
		path: '/suporte',
		icon: <HiOutlineQuestionMarkCircle />
	}
]