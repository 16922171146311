import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/shared/Layout";
import HistoricoPedidos from "./components/HistoricoPedidos";
import Painel from "./components/Painel";
import Cardapio from "./components/Cardapio";
import { AuthProvider } from "./components/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import ConfiguracaoPage from "./components/ConfiguracaoPage";
import Suporte from "./components/Suporte";
import Cupom from "./components/Cupom";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={
              <PrivateRoute>
                <Painel />
              </PrivateRoute>
            } />
            <Route path="cardapio" element={
              <PrivateRoute>
                <Cardapio />
              </PrivateRoute>
            } />
            <Route path="historico-pedidos" element={
              <PrivateRoute>
                <HistoricoPedidos />
              </PrivateRoute>
            } />
            <Route path="configuracao" element={
              <PrivateRoute>
                <ConfiguracaoPage />
              </PrivateRoute>
            } />
            <Route path="cupom" element={
              <PrivateRoute>
                <Cupom />
              </PrivateRoute>
            } />
            <Route path="suporte" element={
              <PrivateRoute>
                <Suporte />
              </PrivateRoute>
            } />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
