import React, { useState, useEffect, useRef } from 'react';
import BackendService from '../integrations/BackendService';
import Swal from 'sweetalert2';
import { v4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage"
import { imageDb } from '../integrations/FirebaseConfig';
import {
    CurrencyInput,
    Currencies,
    Locales
} from 'input-currency-react';

const backendService = new BackendService();

export default function ConfiguracaoPage() {
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const [config, setConfig] = useState({
        id: null,
        nome: '',
        cnpj: '',
        endereco: {
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            cep: ''
        },
        configuracaoRestaurante: {
            idLogo: '',
            urlLogo: '',
            categoria: '',
            horarioAbertura: '',
            horarioFechamento: '',
            valorFrete: '',
            aberto: false,
            segunda: false,
            terca: false,
            quarta: false,
            quinta: false,
            sexta: false,
            sabado: false,
            domingo: false
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await backendService.buscarConfiguracaoRestaurante();
                const valorFrete = parseFloat(response.configuracaoRestaurante.valorFrete).toFixed(2);

                const configResponse = {
                    ...response,
                    configuracaoRestaurante: {
                        ...response.configuracaoRestaurante,
                        valorFrete: valorFrete
                    }
                };

                setConfig(configResponse);
            } catch (error) {
                console.error('Erro ao buscar configuração:', error.message);
            }
        };
        fetchData();
    }, []);

    const diasDaSemanaMapeados = {
        segunda: 'segunda-feira',
        terca: 'terça-feira',
        quarta: 'quarta-feira',
        quinta: 'quinta-feira',
        sexta: 'sexta-feira',
        sabado: 'sábado',
        domingo: 'domingo'
    };

    const diasDaSemana = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];

    const handleChangeConfiguracao = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevState => ({
            ...prevState,
            configuracaoRestaurante: {
                ...prevState.configuracaoRestaurante,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    };

    const handleSubmit = async () => {
        if (!config.configuracaoRestaurante.valorFrete || isNaN(config.configuracaoRestaurante.valorFrete) || parseFloat(config.configuracaoRestaurante.valorFrete) === 0) {
            Swal.fire({
                text: "É necessário ter um valor do frete.",
                icon: "info"
            });
            return;
        }
        Swal.fire({
            title: 'Salvando...',
            text: 'Por favor, aguarde enquanto salvamos sua alteração.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const uuid = v4();
        const isNewImage = config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File

        if (isNewImage) {
            const imgRef = ref(imageDb, `${config.id}/logo/${uuid}`);
            await uploadBytes(imgRef, config.configuracaoRestaurante.urlLogo); // Ensure this is the correct file reference

            setConfig(prevState => ({
                ...prevState,
                configuracaoRestaurante: {
                    ...prevState.configuracaoRestaurante,
                    idLogo: uuid // or imgRef.fullPath if you want the full path
                }
            }));
        }

        const { urlLogo, ...rest } = config.configuracaoRestaurante;

        const configuracaoParaEditar = {
            ...config,
            configuracaoRestaurante: {
                ...rest,
                idLogo: isNewImage ? uuid : config.configuracaoRestaurante.idLogo
            }
        };

        
        salvarEdicaoConfiguracaoRestaurante(configuracaoParaEditar)
    };

    const salvarEdicaoConfiguracaoRestaurante = async (config) => {
        try {
            await backendService.salvarConfiguracaoRestaurante(config.configuracaoRestaurante);
            Swal.fire(
                'Configuração alterada!',
                'A configuração do seu restaurante foi alterada.',
                'success'
            );
        } catch (error) {
            console.error(error.message);
            Swal.fire(
                'Erro!',
                'Não foi possível alterar a configuração do seu restaurante.',
                'error'
            );
            console.error('Erro ao editar configuração:', error.message);
        }
    }

    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChangeLogo = (valor) => {
        const file = valor.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                Swal.fire('Erro!', 'O arquivo selecionado é muito grande. O tamanho máximo permitido é 2 MB.', 'error');
                return;
            }
            setConfig(prevState => ({
                ...prevState,
                configuracaoRestaurante: {
                    ...prevState.configuracaoRestaurante,
                    urlLogo: file
                }
            }));
        }
    }

    const renderImage = () => {
        if (typeof config.configuracaoRestaurante.urlLogo === 'string') {
            // Considera como URL
            return (
                <img
                    src={config.configuracaoRestaurante.urlLogo}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        } else if (config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File) {
            // Considera como Blob ou File
            return (
                <img
                    src={URL.createObjectURL(config.configuracaoRestaurante.urlLogo)}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        }
    };

    const handleChangePreco = (campo, valor) => {
        valor = valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setConfig({
            ...config,
            configuracaoRestaurante: {
                ...config.configuracaoRestaurante,
                ['valorFrete']: valor
            }
        });
    };


    return (
        <div className="flex flex-col justify-between overflow-y-auto  h-screen p-4">
            <div className=" text-center flex flex-row items-center">
                {!config.configuracaoRestaurante.urlLogo && (
                    <button
                        className="button-upload rounded-full shadow-lg p-2"
                        onClick={handleClick}
                        style={{ width: '100px', height: '100px' }} // Definindo a largura e altura para torná-lo um círculo
                    >
                        LOGO
                    </button>
                )}
                {config.configuracaoRestaurante.urlLogo && (
                    <div className='flex items-center justify-center'>
                        {renderImage()}
                    </div>

                )}
                <span className='ml-5 text-2xl font-bold text-center'>{config.nome}</span>
                <input
                    type="file"
                    onChange={handleChangeLogo}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Status estabelecimento:</label>
                <input
                    type="checkbox"
                    name="aberto"
                    checked={config.configuracaoRestaurante.aberto}
                    onChange={handleChangeConfiguracao}
                    className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Aberto</span>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Horário de Abertura:</label>
                <input
                    type="time"
                    name="horarioAbertura"
                    value={config.configuracaoRestaurante.horarioAbertura}
                    onChange={handleChangeConfiguracao}
                    className="mt-1 p-2 w-full border rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Horário de Fechamento:</label>
                <input
                    type="time"
                    name="horarioFechamento"
                    value={config.configuracaoRestaurante.horarioFechamento}
                    onChange={handleChangeConfiguracao}
                    className="mt-1 p-2 w-full border rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Valor do Frete:</label>
                <CurrencyInput options={{
                    precision: 2,
                    style: "currency",
                    locale: Locales["Portuguese (Brazil)"], // Format Type
                    i18nCurrency: Currencies["Brazilian Real"] // Symbol
                }}
                    placeholder="Preço" value={config.configuracaoRestaurante.valorFrete} onChangeEvent={handleChangePreco} className="w-full mb-4 px-3 py-2 border rounded-lg" />
            </div>

            {diasDaSemana.map((dia) => (
                <div className="" key={dia}>
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            name={dia}
                            checked={config.configuracaoRestaurante[dia]}
                            onChange={handleChangeConfiguracao}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">{diasDaSemanaMapeados[dia]}</span>
                    </label>
                </div>
            ))}
            <button onClick={handleSubmit} type="submit" className="w-full bg-blue-500 text-white py-2 rounded-md mt-4 hover:bg-blue-600">
                Salvar
            </button>
        </div>
    );
}
