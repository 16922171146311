import React, { useState } from 'react'
import PedidosNovos from './PedidoNovo/PedidosNovos'
import DetalhesPedido from './DetalhesPedido/DetalhesPedido'

export default function Painel() {
  const [detalhesPedido, setDetalhesPedido] = useState(null);

  const handlePedidoItemClick = (pedido) => {
    setDetalhesPedido(pedido);
  };

  return (
    <div className='flex h-screen  p-4 overflow-y-auto '>
      <PedidosNovos onPedidoItemClick={handlePedidoItemClick} />
      <DetalhesPedido pedido={detalhesPedido} />
    </div>
  )
}
