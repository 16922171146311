import React, { useState, useRef } from 'react'
import ItemsPedido from './ItemsPedido'
import BackendService from '../../integrations/BackendService'

export default function DetalhesPedido({ pedido }) {
    const backendService = new BackendService();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [despacharModalOpen, setDespacharModalOpen] = useState(false);
    const [finalizarModalOpen, setFinalizarModalOpen] = useState(false);

    const openConfirmModal = () => setConfirmModalOpen(true);
    const closeConfirmModal = () => setConfirmModalOpen(false);

    const openCancelModal = () => setCancelModalOpen(true);
    const closeCancelModal = () => setCancelModalOpen(false);

    const openDespacharModal = () => setDespacharModalOpen(true);
    const closeDespacharModal = () => setDespacharModalOpen(false);

    const openFinalizarModal = () => setFinalizarModalOpen(true);
    const closeFinalizarModal = () => setFinalizarModalOpen(false);
    const componentRef = useRef();

    const handleCancel = async () => {
        try {
            await backendService.cancelarPedido(pedido.id);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeCancelModal();
    };

    const handleConfirm = async () => {
        try {
            await backendService.confirmarPedido(pedido.id);
            await imprimirAndRefresh()
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const handleDespachar = async () => {
        try {
            await backendService.despacharPedido(pedido.id);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const handleFinalizar = async () => {
        try {
            await backendService.finalizarPedido(pedido.id);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const imprimirAndRefresh = async () => {
        handlePrintNative(true);
    }

    const imprimir = async () => {
        await new Promise((resolve) => {
            setTimeout(() => {
                handlePrintNative(false);
                resolve();
            }, 200); // Delay de 1 segundo
        });
    }

    const handlePrintNative = (refresh) => {
        const printContent = componentRef.current;
        const windowPrint = window.open('', '', 'width=800,height=600');
        const windowPrintDocument = windowPrint.document;

        windowPrintDocument.write(`
            <html>
              <head>
                <title>Imprimir Pedido</title>
              </head>
              <body>
                  ${printContent.innerHTML}
              </body>
            </html>
          `);

        windowPrintDocument.close();
        setTimeout(() => {
            if(refresh){
                window.location.reload();
            }
            windowPrint.close()
        }, 200)
        windowPrint.focus();
        windowPrint.print();

    };

    if (!pedido) {
        return (
            <div className='flex items-center justify-center min-h-[30rem] max-h-[50rem] w-full p-4'>
                <span>Por favor, selecione um pedido para ver os detalhes.</span>
            </div>
        );
    }

    const dataHoraPedido = new Date(pedido.dataHoraPedido);

    // Extrair e formatar a data
    const dia = dataHoraPedido.getDate().toString().padStart(2, '0');
    const mes = (dataHoraPedido.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna 0-11
    const ano = dataHoraPedido.getFullYear();
    const dataFormatada = `${dia}/${mes}/${ano}`;

    // Extrair e formatar a hora
    const horas = dataHoraPedido.getHours().toString().padStart(2, '0');
    const minutos = dataHoraPedido.getMinutes().toString().padStart(2, '0');
    const horaFormatada = `${horas}:${minutos}`;

    return (
        <div className='flex flex-col w-full '>
            <div className='flex flex-col w-full h-full p-2 bg-white rounded-lg shadow-lg'>
                <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>Pedido #{pedido.id} - Realizado {dataFormatada} às {horaFormatada}</span>
                        <span style={{ color: '#4A5568' }}>Endereço: {pedido.endereco.logradouro}, {pedido.endereco.numero}, {pedido.endereco.bairro} - {pedido.endereco.complemento} - CEP {pedido.endereco.cep} - {pedido.endereco.cidade}</span>
                    </div>
                    <ItemsPedido pedido={pedido} />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                        <span style={{ marginLeft: 'auto', color: '#4A5568', marginTop: '8px' }}>
                            Total Itens: <span style={{ fontWeight: '600' }}>R$ {pedido.itensPedido.reduce((acc, item) => acc + item.precoTotal, 0).toFixed(2)}</span>
                        </span>
                        {pedido.cupom && (
                            <>
                                {pedido.cupom.tipoDesconto === 'VALOR' ? (
                                    <span style={{ marginLeft: 'auto', color: '#16A34A', fontWeight: '600' }}>
                                        Cupom R$ {pedido.cupom.desconto.toFixed(2)}
                                    </span>
                                ) : (
                                    <span style={{ marginLeft: 'auto', color: '#16A34A', fontWeight: '600' }}>
                                        Cupom {pedido.cupom.desconto.toFixed(2)}%
                                    </span>
                                )}
                            </>
                        )}
                        <span style={{ marginLeft: 'auto', color: '#4A5568' }}>Frete: <span style={{ fontWeight: '600' }}>R$ {pedido.valorFrete.toFixed(2)}</span></span>
                        <span style={{ marginLeft: 'auto', color: '#4A5568' }}>Total: <span style={{ fontWeight: '600', fontSize: '1.25rem', color: '#000' }}>R$ {pedido.valorTotal.toFixed(2)}</span></span>
                        <span style={{ marginLeft: 'auto', color: '#4A5568' }}>Forma de Pagamento: <b>{pedido.formaPagamento}</b></span>
                        {pedido.trocoPara !== undefined && pedido.trocoPara !== null && pedido.trocoPara !== 0 && (
                            <span style={{ marginLeft: 'auto', color: '#4A5568', marginBottom: '8px' }}>Troco para: R$ {pedido.trocoPara.toFixed(2)}</span>
                        )}
                    </div>
                </div>
                <div className='flex w-full justify-between'>
                    <div>
                        <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                            onClick={openCancelModal}>
                            Cancelar
                        </button>
                        {pedido.statusPedido === "DESPACHADO" ? (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openFinalizarModal}>
                                Finalizar
                            </button>
                        ) : pedido.statusPedido === "AGUARDANDO_CONFIRMACAO" ? (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openConfirmModal}>
                                Confirmar
                            </button>
                        ) : (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openDespacharModal}>
                                Despachar
                            </button>
                        )}
                    </div>
                    {pedido.statusPedido !== "AGUARDANDO_CONFIRMACAO" && (
                        <div>
                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={imprimir}>
                                Imprimir Pedido
                            </button>
                        </div>
                    )}
                </div>
            </div>


            {/* Modal de cancelamento */}
            {cancelModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                        <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                            <div className=" flex flex-col items-center  py-4 px-6">
                                <p>Deseja realmente cancelar o pedido?</p>
                                <div className="mt-4">
                                    <button onClick={handleCancel} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                                        Sim
                                    </button>
                                    <button onClick={closeCancelModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ml-2">
                                        Não
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de confirmação */}
            {confirmModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                        <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                            <div className=" flex flex-col items-center  py-4 px-6">
                                <p>Deseja realmente confirmar o pedido?</p>
                                <div className="mt-4">
                                    <button onClick={handleConfirm} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                                        Sim
                                    </button>
                                    <button onClick={closeConfirmModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ml-2">
                                        Não
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de despachar */}
            {despacharModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                        <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                            <div className=" flex flex-col items-center  py-4 px-6">
                                <p>Deseja realmente despachar o pedido?</p>
                                <div className="mt-4">
                                    <button onClick={closeDespacharModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ">
                                        Não
                                    </button>
                                    <button onClick={handleDespachar} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-10">
                                        Sim
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de encerrar */}
            {finalizarModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                        <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                            <div className=" flex flex-col items-center  py-4 px-6">
                                <p>Deseja realmente finalizar o pedido?</p>
                                <div className="mt-4">
                                    <button onClick={closeFinalizarModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ">
                                        Não
                                    </button>
                                    <button onClick={handleFinalizar} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-10">
                                        Sim
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}
