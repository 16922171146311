const Global = {
    // Firebase
    firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'AIzaSyAKA2yVekS7LfZMrAltV0cMcMYwJuywc68',
    firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'foomedeque-hml.firebaseapp.com',
    firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'foomedeque-hml',
    firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'foomedeque-hml.appspot.com',
    firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '176158056628',
    firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID ?? '1:176158056628:web:156c6e0f81b7f47b4bb7e1',
    firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? 'G-M1DNVDL5MF',

    backendUrl: process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080/'
  };
  
  export default Global;