import React, { useEffect, useState } from 'react'
import PedidoItem from './PedidoItem'
import BackendService from '../../integrations/BackendService';

export default function PedidosNovos({ onPedidoItemClick }) {

  const backendService = new BackendService();

  const fetchData = async () => {
    try {
      const pedidos = await backendService.getPedidos();
      const pedidosAprovados = pedidos.filter(pedido => pedido.statusPedido === 'EM_PREPARO');
      const pedidosParaAprovar = pedidos.filter(pedido => pedido.statusPedido === 'AGUARDANDO_CONFIRMACAO');
      const pedidosDespachados = pedidos.filter(pedido => pedido.statusPedido === 'DESPACHADO');
      setListaPedidosAprovados(pedidosAprovados);
      setListaPedidosParaAprovar(pedidosParaAprovar);
      setListaPedidosDespachados(pedidosDespachados);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // [] garante que fetchData será chamado apenas uma vez, na montagem do componente.

  useEffect(() => {
    const connectEventSource = () => {
      const baseURL =  backendService.api.defaults.baseURL;
      const eventSource = new EventSource(`${baseURL}events/${localStorage.getItem('restaurante')}`);
  
      eventSource.onmessage = (event) => {
        console.log('Evento SSE recebido:', event.data);
        fetchData();
      };
  
      eventSource.onerror = (error) => {
        console.error('Erro na conexão SSE:', error);
        eventSource.close();
  
        setTimeout(() => {
          connectEventSource();
          fetchData(); // Sincroniza dados após reconectar
        }, 1000); // Intervalo reduzido para 1 segundo
      };
  
      // Retorna uma função de cleanup que será chamada quando o componente for desmontado
      return eventSource;
    };
  
    const eventSource = connectEventSource();
  
    // Função de cleanup que fecha a conexão SSE quando o componente for desmontado
    return () => {
      eventSource.close();
      console.log('Conexão SSE fechada');
    };
  }, []);

  const [abaAtiva, setAbaAtiva] = useState('paraAprovar'); // estado para controlar a aba ativa
  const [listaPedidosAprovados, setListaPedidosAprovados] = useState([])
  const [listaPedidosParaAprovar, setListaPedidosParaAprovar] = useState([])
  const [listaPedidosDespachados, setListaPedidosDespachados] = useState([])

  const handleChangeAba = (aba) => {
    setAbaAtiva(aba); // atualiza o estado para a aba selecionada
    onPedidoItemClick(null)
  };
  return (
    <div className='flex flex-col justify-between bg-white shadow-md rounded-md min-w-[30rem] mr-5'>
      <div className="flex">
        <button
          className={`p-2 rounded-t-lg w-full py-4 ${abaAtiva === 'paraAprovar' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
          onClick={() => handleChangeAba('paraAprovar')}
        >
          Pedidos para Aprovar
        </button>
        <button
          className={`p-2 rounded-t-lg w-full py-4 ${abaAtiva === 'aprovados' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
          onClick={() => handleChangeAba('aprovados')}
        >
          Pedidos em Preparo
        </button>
        <button
          className={`p-2 rounded-t-lg w-full py-4 ${abaAtiva === 'despachados' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
          onClick={() => handleChangeAba('despachados')}
        >
          Pedidos Despachados
        </button>
      </div>
      <div className='flex-1 mb-3 overflow-y-auto px-2 bg-slate-100'>
        {/* Lista de Pedidos */}
        {abaAtiva === 'paraAprovar' ? (
          listaPedidosParaAprovar.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))
        ) : abaAtiva === 'aprovados' ? (
          listaPedidosAprovados.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))
        ) : (
          listaPedidosDespachados.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))
        )}
      </div>

      <div className='flex items-center justify-center h-[3rem]'>
        Total Pedidos: {abaAtiva === 'paraAprovar' ? (listaPedidosParaAprovar.length) : abaAtiva === 'aprovados' ? (listaPedidosAprovados.length) : (listaPedidosDespachados.length)}
      </div>
    </div>

  )
}
