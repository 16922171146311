import React from "react";

export default function Item({ item }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				borderBottom: "1px solid #D1D5DB",
				alignItems: "center",
				marginBottom: "24px",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<span>
						{item.quantidade}x {item.nome}
					</span>
					<span>R$ {item.precoTotal.toFixed(2)}</span>
				</div>

				{/* Exibir Adicionais se não estiver vazio */}
				{item.adicional && item.adicional.trim() !== "" && (
					<div style={{ fontSize: "0.875rem", color: "#6B7280" }}>
						Adicionais:{" "}
						{item.adicional.split(",").map((adicional, index) => (
							<span key={index} style={{ display: "block" }}>
								{adicional.trim()}
							</span>
						))}
					</div>
				)}

				{/* Exibir Observação se não estiver vazio */}
				{item.observacao && item.observacao.trim() !== "" && (
					<span style={{ fontSize: "0.875rem", color: "#6B7280" }}>
						Observação: {item.observacao}
					</span>
				)}
			</div>
		</div>
	);
}
