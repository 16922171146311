import classNames from 'classnames'
import React from 'react'
import { HiOutlineLogout } from 'react-icons/hi'
import { DASHBOARD_SIDEBAR_BOTTOM_LINKS, DASHBOARD_SIDEBAR_LINKS } from '../../lib/constants/naviation'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../AuthContext'

const linkClass =
    'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base'

export default function Sidebar() {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
    };
    return (
        <div className='flex flex-col bg-neutral-900 w-60 p-3 text-white'>
            <div className='flex justify-center items-center gap-2 px-1 py-3'>  
            <img
                    src="/logopng.png"
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    
                />
            </div>
            <div className='flex-1 py-8 flex flex-col gap-0.5'>
                {DASHBOARD_SIDEBAR_LINKS.map((item) => (
                    <SidebarLink item={item} />
                ))}
            </div>
            <div className='flex flex-col gap-0.5 pt-2 border-t border-neutral-700'>
                {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
                    <SidebarLink item={item}/>
                ))}
                <div className={classNames(linkClass, 'cursor-pointer text-red-500')}  onClick={handleLogout}>
					<span className="text-xl">
						<HiOutlineLogout />
					</span>
					Logout
				</div>
            </div>
        </div>
    )
}


function SidebarLink({ item }) {
    const {pathname} = useLocation()

    return (
        <Link to={item.path} className={classNames( pathname === item.path ? 'bg-neutral-700 text-white' : 'text-neutral-400' ,linkClass)} >
            <span className='text-xl'>{item.icon}</span>
            {item.label}
        </Link>
    )
}