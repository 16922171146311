import React from 'react'

export default function PedidoItem({pedido, onClick }) {

  const handleClick = () => {
    // Chama a função de retorno de chamada (callback) e passa o pedido como argumento
    onClick(pedido);
  };


  return (
    <button className='flex flex-col my-3 rounded-md w-full min-h-[6rem] p-2 bg-white drop-shadow-lg hover:bg-blue-100 text-left' onClick={handleClick}>
      <span>#{pedido.id}</span>
      <span>Endereço: {pedido.endereco.logradouro}, {pedido.endereco.complemento}</span>
      <span>Valor: R$ {pedido.valorTotal.toFixed(2)}</span>
    </button>
  )
}
